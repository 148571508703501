









































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {
      loading: true,
      windowsList: [],
      macList: []
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      language: "language"
    }),
    ...mapState("assets", {
      oss: "oss"
    })
  },
  methods: {
    onDownload(url: string) {
      download(url);
    }
  },
  mounted() {
    Promise.all([
      dispatch.diyGetAMMaster("windows"),
      dispatch.diyGetAMMaster("mac")
    ])
      .then(results => {
        this.windowsList = results[0].data;
        this.macList = results[1].data;
        this.loading = false;
      })
      .catch(error => {
        console.error(error);
        setTimeout(window.location.reload, 3000);
      });
  }
});
